<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div>
        <div>
          <b-button
            v-if="$hasFonction(user, 'Effectuer opération')"
            size="sm"
            variant="outline-primary"
            class="ml-2 mt-2 mt-xl-0"
            v-b-modal.operation-modal
            @click="openOperation()"
          >
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            <span style="margin-left: 10px">OPERATION</span>
          </b-button>
          <b-button
            v-if="$hasFonction(user, 'Effectuer un transfert')"
            size="sm"
            variant="outline-primary"
            class="ml-2 mt-2 mt-xl-0"
            v-b-modal.transfert-modal
            @click="openTransfert()"
          >
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            <span style="margin-left: 10px">TRANSFERT</span>
          </b-button>
          <b-button
            v-if="$hasFonction(user, 'Effectuer Retrait Associé')"
            size="sm"
            variant="outline-primary"
            class="ml-2 mt-2 mt-xl-0"
            v-b-modal.modal-add-date-arrivee
            @click="openRetrait()"
          >
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            <span style="margin-left: 10px">RETRAIT ASSOCIE</span>
          </b-button>
          <b-button
            v-if="$hasFonction(user, 'Effectuer règlement')"
            size="sm"
            variant="outline-primary"
            class="ml-1 mt-2 mt-xl-0"
            v-b-modal.relement-modal
            @click="openReglement()"
          >
            <b-icon-check-circle-fill></b-icon-check-circle-fill>
            <span style="margin: 0px 5px">REGLEMENT</span>
          </b-button>
          <!-- bouton voir point start -->
          <b-button
            v-if="$hasFonction(user, 'Voir compte')"
            class="ml-1 mt-2 mt-xl-0"
            variant="primary"
            size="sm"
            @click="showReport('print')"
          >
            <b-icon-eye-fill></b-icon-eye-fill>
            VOIR LE POINT
          </b-button>
          <!-- bouton voir point end -->

          <!-- bouton export start -->
          <b-button
            v-if="$hasFonction(user, 'Voir compte')"
            variant="outline-primary"
            size="sm"
            class="ml-1 mt-2 mt-xl-0"
            @click="ExportFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>

          <!-- bouton export end -->
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="mt-2 mx-1 row justify-content-center">
            <!-- champ recherche start -->
            <b-form-group class="col-lg-2 mt-2 mt-xl-0">
              <b-form-input
                placeholder="Rechercher"
                type="text"
                class="d-inline-block"
                @input="filterValue()"
                v-model="valueSearch"
              />
            </b-form-group>
            <!-- champ recherche end -->

            <!-- date start -->
            <div class="col-lg-2 mt-2 mt-xl-0">
              <b-form-input
              placeholder="Date de début"
              v-model="valueStart"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="filterValue()"
              />
              <label>Date début</label>
              <span
                class="text-danger"
                style="font-size: 0.9em; margin-top: 5px; margin-left: 8px"
              >
                {{ errorValueStart }}
              </span>
            </div>

            <div class="col-lg-2 mt-2 mt-xl-0">
              <b-form-input
              placeholder="Date de fin"
              v-model="valueEnd"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="filterValue()"
              />
              <label>Date de fin</label>
            </div>
            <!-- date end -->

            <!-- select caisse start -->
            <b-form-select
              v-model="selectedCaisse"
              :options="associationsWithCaisse"
              value-field="text"
              class="col-lg-2 mt-2 mt-xl-0 ml-0"
              @input="filterValue()"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                  >-- Caisse concernée --</b-form-select-option
                >
              </template>
            </b-form-select>
            <!-- select caisse end -->
            <!-- solde start -->
            <div class="col-lg-2 mt-2 mt-xl-0">
              <b-input-group prepend="Solde">
                <b-form-input
                  v-model="solde"
                  type="text"
                  disabled
                  placeholder="Solde"
                >
                </b-form-input>
              </b-input-group>
            </div>

                      <div class="col-lg-2 ml-0 mt-2 mt-xl-0">
                          <b-form-select
                                      ref="status_filter"
                                      v-model="selectedTrie"
                                      :options="trieDate"
                                      @change="changeTrie"
                                      @input="filterValue"
                                  >
                          </b-form-select>
                          <label>Trier Par Date</label>
                      </div>
            <!-- solde end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="excelJournalCaisse"
            id="my-table"
            responsive
            :fields="headers"
            :items="journalCaisses"
            @filtered="onFiltered"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-function="filterFunction"
            show-empty
            class="border-bottom mt-2"
          >
            <template  #cell(entree)="data">
              <span>
                {{
                  data.item.entree
                    ? $thousandSeparator(data.item.entree)
                    : "----"
                }}
              </span>
            </template>
            <template #cell(sortie)="data">
              <span>
                {{
                  data.item.sortie
                    ? $thousandSeparator(data.item.sortie)
                    : "----"
                }}
              </span>
            </template>

            <template #cell(solde)="data">
              {{ data.item.solde ? $thousandSeparator(data.item.solde) : 0 }}
            </template>

            <template #cell(created_at)="data">
              <span>
                {{ data.item.created_at ? data.item.created_at : "----" }}
              </span>
            </template>

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>

            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>

              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span>
	                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                  </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>

            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>
      </div>
    </div>

    <!-- Modals -->
    <div>
      <!-- modal operation -->
      <b-modal
        size="lg"
        id="operation-modal"
        content-class="shadow"
        v-model="dialogOperation"
        :ok-disabled="isOperating"
        no-close-on-backdrop
        title="Effectuer une opération"
        hide-footer
        centered
      >
        <validation-observer ref="reglementForm">
          <b-form
            ref="reglementForm"
            @submit.stop.prevent="snackConfirm('operation')"
          >
            <b-row class="formRow">
              <b-col class="col">
                  <validation-provider #default="{ errors }" name="compte_source">
                    <label for="libelle">Type</label>
                    <combo-box-component
                              id="type_operation"
                              :dataSource="typesList"
                              :fields="fieldsComboClients"
                              v-model="oper_type"
                              :state="errors.length > 0 ? false : null"
                          >
                          </combo-box-component>
                    <small class="text-danger">{{
                        errors[0] ? errors[0] : erreurs.compte_source
                      }}</small>
                  </validation-provider>
                </b-col>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="operation_type"
                    rules="required"
                  >
                    <label for="ci">Type d'opération</label>
                    <b-form-select
                      id="Type_opration"
                      v-model="operationForm.operation_type"
                      placeholder="Type d'opération"
                      @change="checkOperationType('operation')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option  value="entree">
                        Entrée caisse
                      </b-form-select-option>
                      <b-form-select-option value="sortie">
                        Sortie caisse
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              <div class="col">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="caisse"
                    rules="required"
                  >
                    <label for="caisse">Caisse concernée</label>


                    <b-form-select
                      id="caisse"
                      @change="checkCaisseType('operation')"
                      v-model="operationForm.caisse_type"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option value="societe">
                        Société
                      </b-form-select-option>
                      <b-form-select-option  value="association">
                        Association
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>

            <b-row class="mt-1">
              <div class="col">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="association"
                    :rules="!disableCaisse ? 'required' : ''"
                  >
                    <label for="libelle">Association</label>
                    <model-list-select
                      :list="associations"
                      id="association"
                      :isDisabled="disableCaisse"
                      v-model="selectedAssociation"
                      option-text="name"
                      option-value="name"
                      @input="checkAssociation()"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.compte_tiers
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col">
                <validation-provider
                  #default="{ errors }"
                  name="banque"
                  rules="required"
                >
                  <label for="libelle">Banque</label>
                  <model-list-select
                    :list="accountsListCP"
                    id="banque"
                    :isDisabled="disableCP"
                    v-model="operationForm.compteCP"
                    option-value="id"
                    option-text="libelle"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.banque
                  }}</small>
                </validation-provider>
              </div>
            </b-row>

            <b-row class="mt-1">
              <div class="col">
                <b-form-group label="Montant" label-for="amount">
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    rules="required"
                  >
                    <b-input-group :append="devPrincipale.abbreviation">
                      <cleave
                        class="form-control"
                        :raw="false"
                        id="amount"
                        placeholder="0"
                        :options="numberFormat"
                        v-model="operationForm.operation_montant"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.montant
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group label="Libellé" label-for="libelle">
                  <validation-provider
                    #default="{ errors }"
                    name="libelle"
                    rules="required"
                  >
                    <b-form-input
                      id="libelle"
                      type="text"
                      placeholder="Libellé"
                      v-model="operationForm.operation_libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.libelle
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>
            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="resetOperation()"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonOperation"
                variant="primary"
                class="mt-2"
                @click="snackConfirm('operation')"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
        <!-- form end -->
      </b-modal>

      <!-- modal reglement -->
      <b-modal
        size="lg"
        id="relement-modal"
        content-class="shadow"
        v-model="dialogReglement"
        :ok-disabled="isOperating"
        no-close-on-backdrop
        title="Effectuer un règlement"
        hide-footer
        centered
      >
        <validation-observer ref="reglementForm">
          <b-form
            ref="reglementForm"
            @submit.stop.prevent="snackConfirm('reglement')"
          >
            <b-row class="formRow">
              <div class="col">
                <validation-provider
                  #default="{ errors }"
                  name="type de caisse"
                  rules="required"
                >
                  <label for="libelle">Type de caisse</label>
                  <b-form-select
                    id="type_caisse"
                    v-model="reglementForm.type_caisse"
                    :options="regementTypes"
                    :disabled="Isimportateur===0"
                    :state="errors.length > 0 ? false : null"
                    @input="checkAssocOrImp()"
                  />
                  <!-- <model-list-select
                    :list="AssociesAndImporter"
                    id="compte"
                    v-model="reglementForm.associe_importateur"
                    option-value="name"
                    option-text="name"
                    @input="checkAssocOrImp()"
                    :state="errors.length > 0 ? false : null"
                  /> -->
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.type_caisse
                  }}</small>
                </validation-provider>
              </div>
              <b-form-group
                class="col"
                label="Caisse"
                label-for="caisse_fsr_imp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="caisse_fsr_imp"
                  rules="required"
                >
                  <model-list-select
                    :list="reglementCaisseList"
                    id="compte"
                    v-model="reglementForm.caisse_ass_imp"
                    option-value="id"
                    option-text="name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.caisse_ass_imp
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
            <b-row class="mb-1">
              <!-- <b-col class="col-6">
                <validation-provider #default="{ errors }" name="compte_source">
                  <label for="libelle">Compte source</label>
                  <b-form-select
                    :disabled="isImportateur"
                    v-model="reglementForm.compte_tiers"
                    text-field="libelle"
                    value-field="id"
                    @input="checkReglementLib()"
                    :options="AssocieComptes"
                  ></b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.compte_source
                  }}</small>
                </validation-provider>
              </b-col> -->
              <b-col class="col-12">
                <validation-provider
                  #default="{ errors }"
                  name="compte concerné"
                  rules="required"
                >
                  <label for="libelle">Compte à régler</label>
                  <b-form-select
                    id="compte_concerne"
                    v-model="reglementForm.compte_concerne"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-select-option
                      :value="item.id"
                      v-for="item in comptesReglement"
                      :disabled="item.disabled"
                      :key="item.id"
                    >
                      {{ item.libelle }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.compte_concerne
                  }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <div class="col">
                <validation-provider
                  #default="{ errors }"
                  name="banque"
                  rules="required"
                >
                  <label for="libelle">Banque</label>
                  <model-list-select
                    :list="accountsListCP"
                    id="banque"
                    v-model="reglementForm.compteCP"
                    option-value="id"
                    option-text="libelle"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.banque
                  }}</small>
                </validation-provider>
              </div>
              <div class="col">
                <b-form-group label="Montant" label-for="amount">
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    rules="required"
                  >
                    <cleave
                      class="form-control"
                      :raw="false"
                      id="amount"
                      :options="numberFormat"
                      placeholder="Montant"
                      v-model="reglementForm.amount"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.montant
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>
            <b-row>
              <div class="col">
                <b-form-group label="Libellé" label-for="libelle">
                  <validation-provider
                    #default="{ errors }"
                    name="libelle"
                    rules="required"
                  >
                    <b-form-input
                      id="libelle"
                      type="text"
                      placeholder="Libelle"
                      v-model="checkReglementLib"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.libelle
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-row>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="resetReglement()"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonReglement"
                variant="primary"
                class="mt-2"
                @click="snackConfirm('reglement')"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
        <!-- form end -->
      </b-modal>

      <!-- modal retrait -->
      <b-modal
        id="retrait-modal"
        v-model="dialogRetrait"
        :ok-disabled="isOperating"
        title="Effectuer un retrait associé"
        size="lg"
        content-class="shadow"
        no-close-on-backdrop
        hide-footer
        centered
      >
        <!-- form start -->
        <validation-observer ref="retraitForm">
          <b-form @submit.stop.prevent="snackConfirm('retraitfsr')">
            <b-row>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="association"
                    rules="required"
                  >
                    <label for="association">Association</label>
                    <model-list-select
                      :list="associations"
                      id="association"
                      v-model="selectedAssociation"
                      option-text="name"
                      option-value="name"
                      @input="checkAssociation()"
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="associe"
                    rules="required"
                  >
                    <label for="associe">Retiré par</label>
                    <model-list-select
                      :list="AssociationAssocies"
                      @input="checkAssocie()"
                      id="associe"
                      v-model="retraitForm.associe"
                      option-value="id"
                      option-text="name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.associe
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="banque"
                    rules="required"
                  >
                    <label for="libelle">Banque</label>
                    <model-list-select
                      :list="accountsListCP"
                      id="banque"
                      v-model="retraitForm.compteCP"
                      option-value="id"
                      option-text="libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.banque
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="montant"
                    rules="required"
                  >
                    <label for="montant">Montant</label>

                    <cleave
                      class="form-control"
                      :raw="false"
                      id="montant"
                      placeholder="0"
                      :options="numberFormat"
                      v-model="retraitForm.montant"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.montant
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="col">
                <b-form-group>
                  <validation-provider name="observation">
                    <label for="observation">Observation</label>
                    <b-form-input
                      v-model="retraitForm.observation"
                      type="text"
                      placeholder="Observation"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="resetRetrait()"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonRetrait"
                variant="primary"
                class="mt-2"
                @click="snackConfirm('retraitfsr')"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
        <!-- form end -->
      </b-modal>
      <!-- modal retrait end-->

      <!-- modal transfert  -->
      <b-modal
        id="transfert-modal"
        v-model="dialogTransfert"
        :ok-disabled="isOperating"
        title="Effectuer un transfert"
        size="lg"
        content-class="shadow"
        no-close-on-backdrop
        hide-footer
        centered
      >
        <!-- form start -->
        <validation-observer ref="tranfertForm">
          <b-form @submit.stop.prevent="snackConfirm('transfert')">
            <b-row>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="operation_type"
                    rules="required"
                  >
                    <label for="ci">Type d'opération</label>
                    <b-form-select
                      id="Type_opration"
                      v-model="transfertForm.operation_type"
                      placeholder="Type d'opération"
                      @change="checkOperationType('transfert')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option selected value="entree">
                        Entrée caisse
                      </b-form-select-option>
                      <b-form-select-option value="sortie">
                        Sortie caisse
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="banque"
                    rules="required"
                  >
                    <label for="libelle">Banque</label>
                    <model-list-select
                      :isDisabled="disableCP"
                      :list="accountsListCP"
                      id="banque"
                      v-model="transfertForm.compteCP"
                      option-value="id"
                      option-text="libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.banque
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="col-12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="libelle"
                    rules="required"
                  >
                    <label for="libelle">Association</label>
                    <model-list-select
                      :list="associations"
                      id="association"
                      v-model="selectedAssociation"
                      @input="checkAssociation"
                      option-text="name"
                      option-value="name"
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="operation_compte_tiers"
                    rules="required"
                  >
                    <label for="operation_compte_tiers"
                      >Pour le compte de</label
                    >
                    <model-list-select
                      :list="AssociationComptesAssocies"
                      id="operation_compte_tiers"
                      v-model="transfertForm.operation_compte_tiers"
                      option-value="libelle"
                      option-text="libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.operation_compte_tiers
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
            </b-row>

            <b-row>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="compte_type"
                    rules="required"
                  >
                    <label for="compte_type">Compte</label>
                    <!-- :list="accountsList" -->
                    <model-list-select
                      :list="AssociationComptesAssocies"
                      id="compte_type"
                      v-model="transfertForm.compte_type"
                      option-value="id"
                      option-text="libelle"
                      @input="getChosenAccount()"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-6">
                <b-form-group>
                  <label for="libelle">Devise du compte</label>
                  <b-form-input disabled v-model="concernedAccountDevise" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="col-12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="libelle"
                    rules="required"
                  >
                    <label for="libelle">Libellé</label>
                    <b-form-input
                      v-model="transfertForm.operation_libelle"
                      placeholder="Libellé"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.operation_libelle
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="operation_montant"
                    rules="required"
                  >
                    <label for="operation_montant">Montant</label>
                    <cleave
                      class="form-control"
                      :raw="false"
                      id="amount"
                      placeholder="0"
                      :options="numberFormat"
                      v-model="transfertForm.operation_montant"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.operation_montant
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="conversion_montant"
                    rules="required"
                  >
                    <label for="conversion_montant">Conversion</label>

                    <cleave
                      :disabled="isPrincipaleDevise"
                      class="form-control"
                      :raw="false"
                      id="amount"
                      placeholder="0"
                      :options="numberFormat"
                      v-model="transfertForm.conversion_montant"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : erreurs.conversion_montant
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="resetTransfert()"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonTransfert"
                variant="primary"
                class="mt-2"
                :disabled="isOperating"
                @click="snackConfirm('transfert')"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
        <!-- form end -->
      </b-modal>
      <!-- modal transfert end-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ModelListSelect } from "vue-search-select";
// import { FormWizard, TabContent } from 'vue-form-wizard'
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  BIconArrowClockwise,
  BIconCircleFill,
  BIconCheckCircleFill,
  BIconEyeFill,
  BFormDatalist,
  BIconCartPlus,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import {
  ComboBoxComponent,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";
import {arrayify} from "vue-flatpickr-component/src/util";

export default {
  components: {
    ModelListSelect,
    ComboBoxComponent,
    ComboBoxPlugin,
    Cleave,
    FormWizard,
    TabContent,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BInputGroupPrepend,
    BPagination,
    BFormSelect,
    BFormDatalist,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BIconCartPlus,
    BIconEyeFill,
    BFormDatepicker,
    BIconCircleFill,
    BIconArrowClockwise,
    BIconCheckCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      dateFormatOptions: { day: "numeric", month: "numeric", year: "numeric" },
      associationsWithCaisse: [],
      valueStart: null,
      valueEnd: null,
      errorValueStart: "",
      oper_type: "",
      entete: "",
      etatButtonOperation: true,
      etatButtonTransfert: true,
      etatButtonRetrait: true,
      etatButtonReglement: true,
      hidden: true,
      comptesList: [],
      typesList: [],
      hiddenEmpty: true,
      hiddenLoader: false,
      selectedCaisse: null,
      perPage: 10,
      typeData: {
        name: "",
      },
      currentPage: 1,
      Isimportateur:0,
      filter: "",
      pageOptions: [],
      journalCaisses: [],
      alljournalCaisses: [],
      journalCaissesList: [],
      selectedAssociation: "",
      erreurs: {},
      headers: [
        {
          key: "created_at",
          label: "Date de l'opération",
          sortable: false,
          // class: 'text-center'
        },
        {
          key: "libelle",
          label: "Libellé",
          sortable: true,
        },
        {
          key: "caisse",
          label: "Caisse concernée",
          sortable: true,
        },
        {
          key: "entree",
          label: "Entrée",
          sortable: true,
        },
        {
          key: "sortie",
          label: "Sortie",
          sortable: true,
        },
        {
          key: "solde",
          label: "Solde",
          sortable: true,
        },
      ],
      excelJournalCaisse: null,
      numberFormat: {
        numeral: true,
        delimiter: " ",
        numeralThousandsGroupStyle: "thousand",
      },

      dialogTransfert: false,
      dialogReglement: false,
      regementTypes: ["Association", "Importateur"],
      reglementCaisseList: [],
      dialogOperation: false,
      dialogRetrait: false,
      dialogPoint: false,
      disableCaisse: false,
      devPrincipale: {},
      comptes: [],
      accountsList: [],
      comptesReglement: [],
      allRows: 0,
      fieldsComboClients: { text: "name", value: "name" },
      comptesFsr: [],
      trieDate: [
        { text: "Croissant", value: "croissant" },
        { text: "Decroissant", value: "decroissant" },
      ],
      selectedTrie: null,
      AssocieComptes: [],
      AssociesAndImporter: [],
      AssociationAssocies: [],
      AssocieListOfAssociations: [],
      AssociationComptesAssocies: [],
      listsCompte: [],
      operationsBackup: [],
      societes: [],
      associes: [],
      compte: {},
      accountsListCP: [],
      importateurs: [],
      associations: [],
      concernedAccounts: [],
      disableCP: true,
      concernedAccountDevise: "",
      showExtra: false,
      soldeActivite: 0,
      soldePret: 0,
      isOperating: false,
      existSociete: false,

      transfertForm: {},
      operationForm: {
          caisse_type: "association"
      },
      retraitForm: {},
      valueSearch:  "",
      reglementForm: {},

      //Comptes tiers
      compte_tiers: true,
      operation_compte_tiers: true,
      message: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    taux() {
      let formateBalance = 1;
      if (
        this.transfertForm.conversion_montant &&
        this.transfertForm.operation_montant
      ) {
        (formateBalance = this.transfertForm.conversion_montant);
          this.options / this.transfertForm.operation_montant;
          this.options;
      }
      return formateBalance ? formateBalance : 1;
    },
    affichetype(value){
    },

    //COMPOSITION DU LIBELLE POUR REGLEMENT
    checkReglementLib() {
      let ReglementPour = null;
      let ReglementMontant = "";
      let ReglementPar = null;

      if (this.reglementForm.caisse_ass_imp) {
        ReglementPar = this.reglementCaisseList.find(
          ({ id }) => id === this.reglementForm.caisse_ass_imp
        );
        ReglementPar = "par " + ReglementPar.name + " ";
      } else {
        ReglementPar = "";
      }

      if (this.reglementForm.compte_concerne) {
        ReglementPour = this.comptesReglement.find(
          ({ id }) => id === this.reglementForm.compte_concerne
        );
        ReglementPour = "pour " + ReglementPour.libelle + " ";
      } else {
        ReglementPour = "";
      }

      if (this.reglementForm.amount) {
        ReglementMontant = "de " + this.reglementForm.amount + " ";
      } else {
        ReglementMontant = "";
      }
      return (this.reglementForm.libelle =
        "Reglement " + ReglementPour + ReglementMontant + ReglementPar + ".");
    },

    //VERIFIER SI UNE DEVISE EST LA DEVISE PRINCIPALE
    isPrincipaleDevise() {
      if (
        this.devPrincipale &&
        this.devPrincipale.name === this.concernedAccountDevise
      ) {
        this.transfertForm.conversion_montant =
          this.transfertForm.operation_montant;
        return true;
      }
      return false;
    },

    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    // calculerSolde() {
    //   let entree = 0;
    //   let sortie = 0;
    //   this.journalCaisses.forEach((element) => {
    //     if (element.entree) {
    //       entree += element.entree;
    //     }

    //     if (element.sortie) {
    //       sortie += element.sortie;
    //     }
    //   });

    //   return entree - sortie;
    // },

    //CALCUL DU SOLDE DU COMPTE DANS LES FILRES
    soldeCompte() {
      let entree = 0;
      let sortie = 0;
      let solde = 0;
      let journalCaisses = this.journalCaisses;

      if (this.caisseFilterValue) {
        journalCaisses = journalCaisses.filter(
          (operation) => operation.caisse == this.caisseFilterValue
        );
      }

      /*
        - Déterminer le premier solde  : il s'agit du montant de la premiere opération (entree ou sortie)
        -
      */
      if (journalCaisses.length > 0) {
        // let lastItem = journalCaisses[journalCaisses.length - 1];
        // lastItem.solde = lastItem.entree ? lastItem.entree : lastItem.sortie ;

        journalCaisses
          .slice()
          .reverse()
          .forEach((element, index) => {
            if (index == 0) {
              element.solde = element.entree ? element.entree : -element.sortie;
            } else if (index > 0) {
              if (element.entree) {
                element.solde =
                  journalCaisses[journalCaisses.length - index].solde +
                  element.entree;
                // element.solde = journalCaisses[journalCaisses.length - index-1].solde - element.sortie ;
              } else if (element.sortie) {
                element.solde =
                  journalCaisses[journalCaisses.length - index].solde -
                  element.sortie;
                // element.solde = journalCaisses[index - 1].solde - element.sortie
              } else {
                element.solde =
                  journalCaisses[journalCaisses.length - index].solde + 0;
              }
            }
          });
      }

      journalCaisses.forEach((element, index) => {
        if (element.entree) {
          entree += element.entree;
        }

        if (element.sortie) {
          sortie += element.sortie;
        }
      });
      this.soldeActivite = entree - sortie + this.soldePret;
      return this.$thousandSeparator(entree - sortie);
    },

    solde() {
      var solde = 0;
      var journalCaissesAfterSelectedCaisse = [];

      if (this.selectedCaisse) {
        journalCaissesAfterSelectedCaisse = this.journalCaissesList.filter(
          (journalCaisse) => journalCaisse.caisse == this.selectedCaisse
        );
      } else {
        journalCaissesAfterSelectedCaisse = this.journalCaissesList;
        //          this.journalCaisses.forEach((element) => {
        //   if (element.entree) {
        //     solde += element.entree;
        //   }

        //   if (element.sortie) {
        //     solde -= element.sortie;
        //   }
        // });
      }

      journalCaissesAfterSelectedCaisse.reverse().forEach((element, index) => {
        if (index == 0) {
          if (element.entree && element.sortie) {
            element.solde = element.entree;
          } else if (element.entree && element.sortie) {
            element.solde = -element.sortie;
          } else {
            element.solde = element.entree - element.sortie;
          }
        } else {
          if (element.entree && element.sortie) {
            element.solde =
              journalCaissesAfterSelectedCaisse[index - 1].solde +
              element.entree;
          } else if (element.entree && element.sortie) {
            element.solde =
              journalCaissesAfterSelectedCaisse[index - 1].solde -
              element.sortie;
          } else {
            element.solde =
              journalCaissesAfterSelectedCaisse[index - 1].solde +
              element.entree -
              element.sortie;
          }
        }
      });

      journalCaissesAfterSelectedCaisse.forEach((element) => {
        if (element.entree) {
          solde += element.entree;
        }

        if (element.sortie) {
          solde -= element.sortie;
        }
      });

      this.journalCaisses = journalCaissesAfterSelectedCaisse.reverse();

      return this.$thousandSeparator(solde);
    },

    //   else {
    //     this.journalCaisses = this.journalCaissesList;

    //     this.journalCaisses.forEach((element) => {
    //       if (element.entree) {
    //         solde += element.entree;
    //       }

    //       if (element.sortie) {
    //         solde -= element.sortie;
    //       }
    //     });

    //     return this.$thousandSeparator(solde);
    //   }
    // },
  },
  created() {
    this.getSocietes();
    this.getAssociations();
    this.getJournalCaisse();
    this.getEntete();
    this.getTypesList();
    this.getDevisesList();
    this.comptesData();
    this.comptesCPData();
    this.associesData();
    this.importateursData();
    this.selectedTrie = "decroissant"
  },
  methods: {
    etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length
        this.currentPage = 1
      },


    async getEntete() {
      await this.$http.get("/societes").then((response) => {
        this.entete = response.data.data[0].id;
      });
    },
    filterValue(val) {
      this.filter = " ";
    },
    dateFilter(start, end, value) {
      this.filter = " ";
      if (!start && !end) {
        return true;
      } else if (start && !end) {
        return value >= start;
      } else if (!start && end) {
        return value <= end;
      }
      return value >= start && value <= end;
    },
    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },

    changeTrie() {
        if(this.selectedTrie == "croissant") {
          this.journalCaisses = this.journalCaisses.reverse();
        }
        if(this.selectedTrie == "decroissant") {
        this.journalCaisses = this.journalCaisses.reverse();
        }
      },


    filterFunction(row, DateFilter) {
      var start = null;
      var end = null;
      var value = new Date(this.toDate(row.created_at));

      if (this.valueStart) {
        var parts = this.valueStart.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }

      if (this.valueEnd) {
        var parts = this.valueEnd.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }

      if (
        row.libelle.toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.caisse.toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1
      ) {
        return false;
      }

      if (this.selectedCaisse != null && this.selectedCaisse != row.caisse) {
        return false;
      } else {
        return this.dateFilter(start, end, value);
      }
    },

    openReglement() {
      this.dialogReglement = true;
      let account = this.accountsListCP.find(
        ({ libelle }) => libelle === "Coffre-fort"
      );
      this.reglementForm.compteCP = account.id;
      this.reglementForm.type_caisse = "Association";
      this.reglementCaisseList = this.associations;
      console.log(this.reglementCaisseList.length)
      if(this.reglementCaisseList.length===1){
        this.reglementForm.caisse_ass_imp=this.reglementCaisseList[0].id;
      }
    },

    openOperation() {
      this.dialogOperation = true;
      let account = this.accountsListCP.find(
        ({ libelle }) => libelle === "Coffre-fort"
      );
      //this.operationForm.operation_type = "entree";
      //this.operationForm.caisse_type = "association";
      this.operationForm.compteCP = account.id;
    },

    openRetrait() {
      this.dialogRetrait = true;
      let account = this.accountsListCP.find(
        ({ libelle }) => libelle === "Coffre-fort"
      );
      this.retraitForm.compteCP = account.id;
    },

    openTransfert() {
      this.dialogTransfert = true;
      //this.transfertForm.operation_type = "entree";
      let account = this.accountsListCP.find(
        ({ libelle }) => libelle === "Coffre-fort"
      );
      this.transfertForm.compteCP = account.id;
    },

    resetTransfert() {
      this.dialogTransfert = false;
      this.transfertForm.operation_type = "";
      this.transfertForm.operation_libelle = "";
      this.transfertForm.compteCP = "";
      this.transfertForm.conversion_montant = 0;
      this.transfertForm.montant = 0;
      this.transfertForm.operation_montant = 0;
      this.transfertForm.compte_type = 0;
      this.selectedAssociation = "";
      this.concernedAccountDevise = "";
    },

    resetOperation() {
      this.dialogOperation = false;
      this.operationForm.operation_type = "";
      this.operationForm.operation_libelle = "";
      this.operationForm.compte_concerne = "";
      this.operationForm.operation_montant = 0;
      this.selectedAssociation = "";
    },

    resetReglement() {
      this.dialogReglement = false;
      this.reglementForm.amount = "";
      this.reglementForm.caisse_fsr_imp = "";
      this.reglementForm.compteCP = "";
      this.reglementForm.compte_concerne = "";
      this.reglementForm.associe_importateur = "";
      this.reglementForm.libelle = "";
    },

    resetRetrait() {
      this.selectedAssociation = "";
      this.dialogRetrait = false;
      this.retraitForm.montant = 0;
      this.retraitForm.compteCP = "";
      this.retraitForm.associe = "";
      this.retraitForm.observation = "";
      this.AssociationAssocies = [];
      this.retraitForm.associe_compte = "";
    },
    getSocietes() {
      this.$http.get("/societes").then((response) => {
        this.societes = response.data.data;
        localStorage.getItem(
            "societyAsImportateur",
            this.societes[0].importateur
        );
        this.Isimportateur=this.societes[0].importateur
      });
    },

    getJournalCaisse() {
          this.$http
              .get("/caisses")
              .then((res) => {
                if (res.status) {
                  this.hiddenLoader = true;
                }
                this.journalCaisses = res.data.data;
                this.journalCaissesList = res.data.data;
                if (this.journalCaissesList.length) {
                  this.hidden = false;
                  this.hiddenEmpty = true;
                } else {
                  this.hidden = true;
                  this.hiddenEmpty = false;
                }
                this.allRows = this.journalCaisses.length
              })
              .catch((e) => {
                console.log("erreur de récupération:" + e.response.data.message);
              });
    },

    /**
     * Fonction pour lancer le filtre
     * lorsqu'on a deux date
     */
    twoDateFiltre(arrayData, data1, data2) {
      var filtreJournalCaissesList = [];

      arrayData.forEach((element) => {
        var parts = element.created_at.split("-");
        var annee = parts[2];
        var mois = parts[1];
        var jour = parts[0];

        var date = annee + "-" + mois + "-" + jour;
        date.toString();

        if (
          Date.parse(date) >= Date.parse(data1) &&
          Date.parse(date) <= Date.parse(data2)
        ) {
          filtreJournalCaissesList.push(element);
        }
      });

      return filtreJournalCaissesList;
    },

    /**
     * Fonction pour lancer le filtre
     * lorsqu'on a seulement la date début
     */
    oneDateFiltre(arrayData, data) {
      var filtreJournalCaissesList = [];

      arrayData.forEach((element) => {
        var parts = element.created_at.split("-");
        var annee = parts[2];
        var mois = parts[1];
        var jour = parts[0];

        var date = annee + "-" + mois + "-" + jour;
        date.toString();
        if (Date.parse(date) >= Date.parse(data)) {
          filtreJournalCaissesList.push(element);
        }
      });

      return filtreJournalCaissesList;
    },

    /**
     * Fonction pour lancer le filtre sur
     * une caisse sélectionné
     */
    caisseFiltre(arrayData, data) {
      var filtreJournalCaissesList = [];

      arrayData.forEach((element) => {
        if (element.caisse == data) {
          filtreJournalCaissesList.push(element);
        }
      });

      return filtreJournalCaissesList;
    },

    dateFiltre() {
      if (this.errorValueStart) {
        this.errorValueStart = "";
      }

      if (this.valueStart) {
        var dateFiltre = {
          date_start: this.valueStart,
          date_end: this.valueEnd,
          caisse: "",
        };

        if (
          this.selectedCaisse &&
          this.selectedCaisse != "-- Caisse concernée --"
        ) {
          dateFiltre.caisse = this.selectedCaisse;
        }

        if (dateFiltre.date_end) {
          var twoDateFiltre = this.twoDateFiltre(
            this.journalCaissesList,
            dateFiltre.date_start,
            dateFiltre.date_end
          );

          if (dateFiltre.caisse) {
            this.journalCaisses = this.caisseFiltre(
              twoDateFiltre,
              dateFiltre.caisse
            );
          } else {
            this.journalCaisses = twoDateFiltre;
          }
        } else {
          var onDateFiltre = this.oneDateFiltre(
            this.journalCaissesList,
            dateFiltre.date_start
          );

          if (dateFiltre.caisse) {
            this.journalCaisses = this.caisseFiltre(
              onDateFiltre,
              dateFiltre.caisse
            );
          } else {
            this.journalCaisses = onDateFiltre;
          }
        }
      } else if (this.valueEnd) {
        this.journalCaisses = this.journalCaissesList;
        this.errorValueStart = "Choisissez une date de début !";
      } else {
        if (this.errorValueStart) {
          this.errorValueStart = "";
        }

        if (
          this.selectedCaisse &&
          this.selectedCaisse != "-- Caisse concernée --"
        ) {
          this.journalCaisseFiltre();
        } else {
          this.journalCaisses = this.journalCaissesList;
        }
      }
    },

    journalCaisseFiltre() {
      if (
        this.selectedCaisse &&
        this.selectedCaisse != "-- Caisse concernée --"
      ) {
        var journalCaisseFiltre = {
          caisse: this.selectedCaisse,
          date_start: "",
          date_end: "",
        };

        if (this.valueStart) {
          journalCaisseFiltre.date_start = this.valueStart;
        }

        if (this.valueEnd) {
          journalCaisseFiltre.date_end = this.valueEnd;
        }

        if (journalCaisseFiltre.caisse) {
          var caisseFiltre = this.caisseFiltre(
            this.journalCaissesList,
            journalCaisseFiltre.caisse
          );

          if (journalCaisseFiltre.date_start) {
            var dateFiltre = [];

            if (journalCaisseFiltre.date_end) {
              dateFiltre = this.twoDateFiltre(
                caisseFiltre,
                journalCaisseFiltre.date_start,
                journalCaisseFiltre.date_end
              );
            } else {
              dateFiltre = this.oneDateFiltre(
                caisseFiltre,
                journalCaisseFiltre.date_start
              );
            }

            this.journalCaisses = dateFiltre;
          } else {
            this.journalCaisses = caisseFiltre;
          }
        }
      } else {
        if (this.valueStart) {
          this.dateFiltre();
        } else {
          this.journalCaisses = this.journalCaissesList;
        }
      }
    },
/*
    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },*/

    showReport(action) {
      const filters = {
        compte: (compte) =>
          this.comptesFilterValue
            ? compte.libelle === this.comptesFilterValue
            : true,
        caisse: (caisse) =>
          this.selectedCaisse ? caisse === this.selectedCaisse : true,
        created_at: (created_at) => {
          let start = this.convert(this.valueStart);
          let end = this.convert(this.valueEnd);

          created_at = this.toDate(created_at);

          if (!start && !end) {
            return true;
          } else if (start && !end) {
            return created_at >= start;
          } else if (!start && end) {
            return created_at <= end;
          }
          created_at = new Date(created_at);
          start = new Date(start);
          end = new Date(end);
          return created_at >= start && created_at <= end;
        },
      };

      let result = this.globalFilter(this.journalCaisses, filters);

      if (action == "print") {
        this.print(result);
      } else {
        return result;
      }
    },

    print(data) {
      let toPrint = {
        type: "Caisse",
        concerne: this.selectedCaisse ? this.selectedCaisse : "Caisse globale",
        headers: this.headers,
        showSoldeColumn: true,
        cahier: data,
        periodeDu: this.convert(this.valueStart),
        periodeAu: this.convert(this.valueEnd),
        solde: this.$thousandSeparator(this.soldeCompte),
        soldeDebut: this.$thousandSeparator(this.soldeDebut(data)),
      };

      this.$router.push({
        name: "operation-print",
        params: { data: toPrint },
      });
    },

    globalFilter(array, filters) {
      if (!array) array = [];
      array.forEach((element) => {
        element["created_at"] = this.convertg(element["created_at"]);
      });
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // je valide tous les critères de tri
        return filterKeys.every((key) => {
          // ignorer les predicats qui ne sont pas des fonctions
          if (typeof filters[key] !== "function") return true;
          return filters[key](item[key]);
        });
      });
    },

    convertg(date) {
      if (date) {
        date = date.replace(/\//g, "-");
      }
      return date;
    },

    convert(date) {
      if (date) {
        date = new Date(date);
      }
      return this.reformatDate(date);
    },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = date.getMonth() + 1;
        let jour = date.getDate();
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },

    soldeDebut(data) {
      let start = this.convert(this.valueStart);
      if (!start) return 0;
      else {
        if (data[data.length]) {
          return data[data.length].solde;
        }
        return 0;
      }
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};
      this.journalCaisses.forEach((element) => {
        obj = {
          "DATE OPERATION(S)": element.created_at,
          "LIBELLE(S)": element.libelle,
          "CAISSE CONCERNÉE(S)": element.caisse,
          "ENTRÉE(S)": element.entree ? element.entree : 0,
          "SORTIE(S)": element.sortie ? element.sortie : 0,
          "SOLDE(S)": element.solde ? element.solde : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste journal caisse",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    getDevisesList() {
      this.$http.get("/devises").then((response) => {
        this.devPrincipale = response.data.data.find(
          ({ devise_principale }) => devise_principale === 1
        );
      });
    },

    getAssociations() {
      this.$http.get("/associations").then((response) => {
        var obj = {};
        this.associations = response.data.data;

          if (this.associations != null && this.associations.length===1) {
        //console.log( this.associations[0].id)
          this.selectedAssociation = this.associations[0].name;
          this.checkAssociation();
        this.AssociationComptesAssocies = this.accountsList.filter(
            (compte) =>{
              console.log("comte",compte)
              if (compte.author_type == "associe" && compte.author_association === 1 )
              {
                return this.transfertForm.compte_type=compte.id;
              }
            }
        )


        }
        this.associationsWithCaisse = [];
        this.associations.forEach((element) => {
          element.text = element.name;
        });
        this.associationsWithCaisse.push({
          value: "caisse",
          text: "Caisse",
          name: "Caisse",
        });
        this.associations.forEach((element) => {
          if (element.name) {
            obj = {
              value: element.id,
              text: element.name,
              name: element.name,
            };
            this.associationsWithCaisse.push(obj);
          }
        });

      });
    },

    checkAssociation() {
      let selectedAssociation = this.associations.find(
        ({ name }) => name === this.selectedAssociation
      );

      this.AssociationAssocies = selectedAssociation.acheteurs_associes.filter(
        (_ass) => _ass.type == "associe"
      );
      let acheteurs = selectedAssociation.acheteurs_associes.filter(
        (_ass) => _ass.type == "acheteur"
      );
      this.AssociationComptesAssocies = this.accountsList.filter(
        (_account) =>
        {
          if ((_account.author_type == "associe") &
              (_account.author_association == selectedAssociation.id) )
          {
            this.concernedAccountDevise=_account.devise;
            return this.transfertForm.compte_type=_account.id;
          }
        }
      );
      acheteurs.forEach((element) => {
        let acc = this.accountsList.find(
          (_account) => _account.libelle == element.name
        );

        if (acc) this.AssociationComptesAssocies.push(acc);
      });

      // this.AssociationAssocies.forEach((element) => {
      //   element.text = element.libelle;
      // });
    },

    checkAssocie() {
      // if (this.retraitForm.associe) {
      //   this.retraitForm.associe = this.AssociationAssocies.find(
      //     (_account) => _account.id == this.retraitForm.associe
      //   ).id;
      // }
    },

    comptesCPData() {
      this.$http.get("/caisse-principale-resources").then((response) => {
        this.accountsListCP = response.data.data;
      });
    },

    isNotValid(montant) {
      if (montant) {
        if (mon > 0) {
          return false;
        }
        return true;
      }
      return true;
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    getTypesList() {
      this.$http.get("/types").then((res) => {
        this.typesList = res.data.data.reverse();
        console.log(this.typesList)
        this.typesList.forEach((element) => {
          element.text = element.name;
        });
      });
    },
    changeTiers() {
      if (this.compte_tiers) {
        let dep_objet = this.comptes.find(
          ({ id }) => id === this.transfertForm.compte_type
        );
        // CHECK IF THE CHOSEN ACCOUNT IS A PROVIDER(FOURNISSEUR) ONE
        if (dep_objet) {
          let b = this.associes.find(({ name }) => name === dep_objet.libelle);
          if (b) this.compte_tiers = false;
        }

        this.transfertForm.operation_societe = "";
      }
    },

    operation_changeTiers() {
      if (!this.operation_compte_tiers) {
        this.operationForm.caisse = null;
      }
    },

    e2CompteTiersChange() {
      if (!this.operationForm.caisse) {
        this.operation_compte_tiers = false;
      }
    },

    async associesData() {
      await this.$http.get("/associes").then((response) => {
        let table = ["Associé", "Chargeur", "Importateur"];
        // if (!table.includes(this.user.user_group.libelle)) {
        //   this.associes.push({ name: "Caisse" });
        //   this.concernedAccounts.push({ name: "Caisse" });
        // }

        response.data.data.forEach((element) => {
          this.associes.push(element);
          this.concernedAccounts.push(element);
          this.AssociesAndImporter.push(element);
        });

        let firstProvider = this.concernedAccounts.find(
          ({ associe_principal }) => associe_principal === 1
        );
        if (firstProvider)
          this.concernedAccounts.splice(
            this.concernedAccounts.indexOf(firstProvider),
            1
          );
      });
    },

    async importateursData() {
      await this.$http.get("/importateurs").then((response) => {
        this.importateurs = response.data.data;
        this.importateurs.forEach((element) => {
          this.AssociesAndImporter.push(element);
        });
      });
    },

    checkAssocOrImp() {
      this.reglementCaisseList = [];
      if (this.reglementForm.type_caisse == "Association") {
        this.comptesReglement= [];
        this.reglementForm.compte_concerne=""
        this.reglementCaisseList = this.associations;
        this.comptesData();
        this.comptesReglement = this.comptesReglement.filter((value)=>["Carte Importateur","Generation Facture","Frais"].includes(value.libelle))
      } else if (this.reglementForm.type_caisse == "Importateur") {
        this.reglementForm.compte_concerne=""
        this.reglementCaisseList = this.importateurs;
        this.comptesReglement = this.comptesReglement.filter((value)=>["Carte Importateur","Generation Facture"].includes(value.libelle))
        console.log(this.comptesReglement)
      }
      // let toFind = this.AssociesAndImporter.find(
      //   ({ name }) => name === this.reglementForm.associe_importateur
      // );
      // if (toFind) {
      //   let isAccos = this.associes.find(
      //     (_assoc) => _assoc.name === toFind.name
      //   );
      //   if (isAccos) {
      //     this.isImportateur = false;
      //     this.AssocieComptes = isAccos.comptes;
      //     this.AssocieListOfAssociations = isAccos.associations;
      //     this.comptesReglement.find(
      //       ({ libelle }) => libelle === "Frais"
      //     ).disabled = false;
      //   } else {
      //     this.isImportateur = true;
      //     this.AssocieComptes = [];
      //     this.AssocieListOfAssociations = [];
      //     let toFindCI = this.comptesReglement.find(
      //       ({ libelle }) => libelle === "Carte Importateur"
      //     );
      //     this.reglementForm.compte_concerne = parseInt(toFindCI.id);
      //     this.reglementForm.compte_tiers = "";

      //   }
      // }
    },
    checkCaisseType(operation) {
      if (this.operationForm.caisse_type == "societe") {
        this.selectedAssociation = "";
        this.disableCaisse = true;
      } else {
        this.disableCaisse = false;
          if (this.associations != null && this.associations.length===1) {
              //console.log( this.associations[0].id)
              this.selectedAssociation = this.associations[0].name;
              this.checkAssociation();
              this.AssociationComptesAssocies = this.accountsList.filter(
                  (compte) =>{
                      console.log("comte",compte)
                      if (compte.author_type == "associe" && compte.author_association === 1 )
                      {
                          return this.transfertForm.compte_type=compte.id;
                      }
                  }
              )


          }
      }
    },

    checkOperationType(operation) {
      switch (operation) {
        case "transfert":
          if (this.transfertForm.operation_type == "entree") {
            let account = this.accountsListCP.find(
              ({ libelle }) => libelle === "Coffre-fort"
            );
            this.transfertForm.compteCP = null;
            this.transfertForm.compteCP = account.id;
            this.disableCP = true;
          } else {
            this.disableCP = false;
          }

          break;

        case "operation":
          if (this.operationForm.operation_type == "entree") {
            let account = this.accountsListCP.find(
              ({ libelle }) => libelle === "Coffre-fort"
            );
            this.operationForm.compteCP = null;
            this.operationForm.compteCP = account.id;
            this.disableCP = true;
          } else {
            this.disableCP = false;
          }

          break;

        default:
          break;
      }
    },

    getChosenAccount() {
      this.isImportateur = false;
      let concernedAccount = this.comptes.find(
        ({ id }) => id === this.transfertForm.compte_type
    );
      if (concernedAccount) {
        this.concernedAccountDevise = concernedAccount.devise;
        this.concernedAccounts = this.associes;
        let firstProvider = this.concernedAccounts.find(
          ({ associe_principal }) => associe_principal === 1
        );
        if (firstProvider) {
          this.concernedAccounts.splice(
            this.concernedAccounts.indexOf(firstProvider),
            1
          );
        }

        // CHECK IF THE CHOSEN ACCOUNT IS A PROVIDER ONE
        // let chosenAccount = this.associes.find(
        //   ({ name }) => name === concernedAccount.libelle
        // );
        // if (chosenAccount) {
        //   this.transfertForm.operation_compte_tiers = chosenAccount.name;
        // } else {
        //   this.transfertForm.operation_compte_tiers = "";
        //   // this.transfertForm.operation_compte_tiers = 'Caisse'
        // }
      }
    },
    async comptesData() {
      await this.$http.get("/comptes").then((response) => {
        this.comptes = response.data.data;
        let avanceP = response.data.data.find(
          ({ libelle }) => libelle === "Prêt Employé/Partenaire"
        );

        if (avanceP) this.soldePret = avanceP.solde;

        response.data.data.forEach((element) => {
          if (
            element.libelle != "Prêt Employé/Partenaire" &&
            element.libelle != "Retrait Associés" &&
            element.libelle != "Carte Importateur" &&
            element.libelle != "Frais" &&
            element.libelle != "Generation Facture"
          ) {
            this.accountsList.push(element);

            this.accountsList.forEach((element) => {
              element.text = element.libelle;
            });
          } else if (
            element.libelle == "Carte Importateur" ||
            element.libelle == "Generation Facture" ||
            element.libelle == "Frais"
          ) {
            this.comptesReglement.push(element);
          }
        });
      });
    },

    snackConfirm(operation) {
      this.message = "";
      this.operationForm.operation_libelle= this.oper_type+" : "+this.operationForm.operation_libelle;
      if (operation == "operation") {
        let lib = this.operationForm.operation_libelle;
        let ct = this.selectedAssociation;
        if (this.operationForm.operation_type===undefined){
          this.showToast('Veuillez choisir le type d\'opération', "danger");
        }else {
          switch (this.operationForm.operation_type) {
            case "sortie":
              this.message =
                  "Confirmez-vous la sortie de caisse de " +
                  this.operationForm.operation_montant +
                  (ct == "Caisse" ? "" : " de " + ct) +
                  " pour : " +
                  lib +
                  " ?";
              break;
            case "entree":
              this.message =
                  "Confirmez-vous l'entrée en caisse de : " +
                  this.operationForm.operation_montant +
                  (ct == "" ? "" : " de " + ct) +
                  " pour : " +
                  lib +
                  " ?";
              break;

            default:
              this.message =
                  "Vous n'avez pas défni le type d'opération. Cette opération ne s'effectuera pas si vous continuez !";
              break;
          }
        }
      } else if (operation == "transfert") {
        let dep_objet = this.comptes.find(
          ({ id }) => id === this.transfertForm.compte_type
        );

        if (dep_objet) dep_objet = dep_objet.libelle;

        let ct = this.selectedAssociation;
        let lib = this.transfertForm.operation_libelle;
        if (this.transfertForm.operation_type===undefined){
          this.showToast('Veuillez choisir le type d\'opération', "danger");
        }else {
          switch (this.transfertForm.operation_type) {
            case "sortie":
              this.message =
                  "Confirmez-vous le transfert de " +
                  this.transfertForm.operation_montant +
                  " de " +
                  (ct == "Caisse"
                      ? "la Caisse"
                      : "la caisse :" + this.selectedAssociation) +
                  " vers le compte : " +
                  dep_objet +
                  " pour : " +
                  lib +
                  " ?";
              break;
            case "entree":
              this.message =
                  "Confirmez-vous le transfert de " +
                  this.transfertForm.operation_montant +
                  " du compte : " +
                  dep_objet +
                  " vers " +
                  (ct == "Caisse"
                      ? "la Caisse"
                      : "la caisse : " + this.selectedAssociation) +
                  " pour : " +
                  lib +
                  " ?";
              break;

            default:
              this.message =
                  "Vous n'avez pas défini le type d'opération. Cette opération ne s'effectuera pas si vous continuez !";
              break;
          }
        }
      } else if (operation == "retraitfsr") {
        let associe = this.AssociationAssocies.find(
          (_account) => _account.id == this.retraitForm.associe
        );
        this.message =
          "Confirmez-vous le retrait de " +
          this.retraitForm.montant +
          " par " +
          associe.name +
          " depuis la caisse de " +
          this.selectedAssociation +
          " ?";
      } else if (operation == "reglement") {
        if (this.reglementForm.type_caisse == "Importateur") {
          this.reglementCaisseList = this.importateurs;
          var toFind = this.importateurs.find(
            (_item) => _item.id === this.reglementForm.caisse_ass_imp
          );
        } else {
          this.reglementCaisseList = this.associations;
          var toFind = this.associations.find(
            (_item) => _item.id === this.reglementForm.caisse_ass_imp
          );
        }

        let toSolde = this.comptesReglement.find(
          ({ id }) => id === this.reglementForm.compte_concerne
        );

        if (toSolde && toFind && this.reglementForm.amount) {
          this.message =
            "Confirmez-vous le règlement de " +
            this.reglementForm.amount +
            " par " +
            toFind.name +
            " au profit du compte " +
            toSolde.libelle +
            " ?";
        }
      }

      this.$bvModal
        .msgBoxConfirm(this.message, {
          title: operation == "retraitfsr" ? "Retrait associé" : operation,
          okVariant: "success",
          cancelVariant: "outline-secondary",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            if (operation == "transfert") {
              this.etatButtonTransfert = false;
              this.saveType()
              this.saveTransfert();
            } else if (operation == "operation") {
              this.etatButtonOperation = false;
              this.saveType()
              this.saveOperation();
            } else if (operation == "retraitfsr") {
              this.etatButtonRetrait = false;
              this.saveType()
              this.saveRetrait();
            } else if (operation == "reglement") {
              this.etatButtonReglement = false;
              this.saveType()
              this.saveReglement();
            } else {
              return;
            }
          } else {
            this.etatButtonOperation = true;
            this.etatButtonTransfert = true;
            this.etatButtonRetrait = true;
            this.etatButtonReglement = true;
          }
        });
    },



    async saveType() {
      let typeExist = this.typesList.find(
          ({ name }) => name === this.oper_type
        );
        this.typeData.name = this.oper_type

        if(!typeExist) {
          await this.$http
          .post("/types", this.typeData)
        .then((result) => {
          console.log(result)
        })
        .catch((err) => {
        });
        }
    },
    async saveTransfert() {
      // if (this.transfertForm.operation_compte_tiers) {
      //   let a = this.comptes.find(
      //     ({ libelle }) => libelle === this.transfertForm.operation_compte_tiers
      //   );
      //   if (a) {
      //     this.transfertForm.operation_compte_tiers = a.id;
      //   } else {
      //     let a = this.importateurs.find(
      //       ({ name }) => name === this.transfertForm.operation_compte_tiers
      //     );
      //     if (a) this.transfertForm.operation_compte_tiers = a.id;
      //     else {
      //       this.transfertForm.operation_compte_tiers = "";
      //     }
      //   }
      // }
      this.transfertForm.operation_montant = this.$thousandFormater(
        this.transfertForm.operation_montant
      );
      this.transfertForm.conversion_montant = this.$thousandFormater(
        this.transfertForm.conversion_montant
      );
      this.transfertForm.caisse =
        this.selectedAssociation != "Caisse"
          ? this.associations.find(
              (_assoc) => _assoc.name == this.selectedAssociation
            ).id
          : null;
        await this.$http
            .post("/caisses", this.transfertForm)
            .then((result) => {
              if (result.data.success == false) {
                this.etatButtonTransfert = true;
                let error = result.data.message;
                error = error[Object.keys(error)[0]][0];
                this.message = error;
              } else {
                this.etatButtonTransfert = true;
                this.showToast(
                    "Nouvelle entrée enregistrée avec succès",
                    "success"
                );
                this.goPrint("transfert", result.data.data, this.entete);
                this.resetTransfert();
                this.getTypesList()
                this.getJournalCaisse();
              }
            })
            .then(() => {
              this.etatButtonTransfert = true;
              this.getJournalCaisse();
            })
            .catch((err) => {
              this.etatButtonTransfert = true;
              this.isOperating = false;
            });

    },

    async saveOperation() {
      // this.closeOperation()

      this.operationForm.operation_montant = this.$thousandFormater(
        this.operationForm.operation_montant
      );
      let selectedAssociation = this.associations.find(
        ({ name }) => name === this.selectedAssociation
      );
      this.operationForm.caisse =
        this.operationForm.caisse_type == "association"
          ? selectedAssociation.id
          : null;
      this.operationForm.compteTiersForPrint =
        this.operationForm.caisse_type == "association"
          ? selectedAssociation.name
          : null;
        await this.$http
            .post("/operations", this.operationForm)
            .then((result) => {
              this.isOperating = false;
              if (result.data.success == false) {
                this.etatButtonOperation = true;
                let error = result.data.message;
                error = error[Object.keys(error)[0]][0];
                this.message = error;
                this.showToast(this.message, "danger");
              } else {
                this.etatButtonOperation = true;
                this.message = "Nouvelle entrée enregistrée avec succès";
                this.goPrint("operation", result.data.data, this.entete);
                this.resetOperation();
                this.getTypesList()
                this.showToast(this.message, "success");
                this.getJournalCaisse();
              }
            })
            .then(() => {
              this.etatButtonOperation = true;
              this.getJournalCaisse();
            })
            .catch((err) => {
              this.etatButtonOperation = true;
              this.message = "L'opération n'a pas abouti";
              this.showToast(this.message, "danger");
            });

    },

    saveRetrait() {
      this.retraitForm.montant = this.$thousandFormater(
        this.retraitForm.montant
      );

      // FOR CAISSE
      this.retraitForm.caisse = this.associations.find(
        ({ name }) => name === this.selectedAssociation
      ).id;

      this.$http
        .post("/retrait-associe-caisse", this.retraitForm)
        .then((result) => {
          this.isOperating = false;
          if (!result.data.success) {
            this.etatButtonRetrait = true;
            let error = result.data.message;
            error = error[Object.keys(error)[0]][0];
            this.showToast(error, false);
          } else {
            this.etatButtonRetrait = true;
            this.showToast("Nouveau retrait enregistré avec succès", "success");
            this.resetRetrait();
            this.getJournalCaisse();
          }
        })
        .catch((err) => {
          this.etatButtonRetrait = true;
          this.isOperating = false;
          this.showToast(err.response.data.message, "danger");
        });
    },

    saveReglement() {
      this.dialogReglement = false;
      // this.reglementForm.associe_importateur_id = this.AssociesAndImporter.find(
      //   ({ name }) => name === this.reglementForm.associe_importateur
      // ).id;
      this.reglementForm.amount = this.$thousandFormater(
        this.reglementForm.amount
      );
      this.reglementForm.societe = this.societes[0].id;
      this.reglementForm.import = this.Isimportateur;
      console.log('Reglement',this.reglementForm)

      this.$http
        .post("/associes-importateurs/reglement", this.reglementForm)
        .then((result) => {
          this.isOperating = false;
          if (!result.data.success) {
            this.etatButtonReglement = true;
            let error = result.data.message;
            error = error[Object.keys(error)[0]][0];
            this.showToast(error, "danger");
          } else {
            this.etatButtonReglement = true;
            this.showToast(
              "Nouveau règlement enregistré avec succès",
              "success"
            );
            this.resetReglement();
            this.getJournalCaisse();
          }
        })
        .catch((err) => {
          this.etatButtonReglement = true;
          this.isOperating = false;
          this.showToast(err.response.data.message, "danger");
        });
    },

    goPrint(type, item, entete) {
      let toPrint;

      switch (type) {
        case "transfert":
          toPrint = {
            type: type,
            operation: item.entree ? "ENTREE" : "SORTIE",
            libelle: item.libelle,
            montant: item.entree ? item.entree : item.sortie,
            compte: item.compte,
            tiers: item.caisse,
            societe: item.societe,
            societe_entete: entete,
            debite: item.entree ? item.compte : item.caisse,
            debiteur: item.entree ? item.caisse : item.compte,
            // societe:  item.operation_societe ? this.societes.find(({ id }) => id === item.operation_societe).name : null
          };
          break;

        case "operation":
          toPrint = {
            type: type,
            operation: item.entree ? "ENTREE" : "SORTIE",
            libelle: item.libelle,
            montant: item.entree ? item.entree : item.sortie,
            tiers: item.caisse,
            societe_entete: entete,
          };
          break;

        default:
          return;
          break;
      }

      let routeData = this.$router.resolve({
        name: "finances-bordereau",
        query: toPrint,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

.vue-form-wizard {
  box-shadow: 0px 0px;
  margin-bottom: 0px !important;
}

.wizard-card-footer {
  height: 20px;
}

.wizard-footer-left .wizard-btn {
  /* color: #27295c !important; */
  /* border: 1px solid #27295c !important; */
  /* background-color: white !important; */
  padding-left: 1px !important;
  padding-top: 1px !important;
  padding-right: 15px !important;
  padding-bottom: 1px !important;
}

.wizard-footer-right .wizard-btn {
  color: #27295c !important;
  border: 1px solid #27295c !important;
  background-color: white !important;
  padding-left: 15px !important;
  padding-top: 1px !important;
  padding-right: 0px !important;
  padding-bottom: 1px !important;
}

.wizard-card-footer {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .annuler {
    margin-bottom: 5px;
  }

  .blockSearch {
    width: 350px !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {
  /* .annuler {
			margin-bottom: 5px;
		} */

  .form {
    margin-left: 10%;
  }

  .formRow .form-group {
    width: 500px;
  }

  .blockForm {
    margin-left: 0px !important;
  }

  .blockSearch {
    width: 80% !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }

  .BlockbButton {
    padding-right: 0px !important;
    margin-right: 40px !important;
  }
}

@media screen and (max-width: 1000px) {
  #solde-input {
    margin-left: 20px !important;
  }

  .form {
    margin-left: 12%;
  }

  .fonction {
    width: 520px;
  }

  .BlockbButton {
    padding-right: 130px !important;
  }
}
</style>
